<template lang="pug">
//- div(
//-     class="check-button ion-justify-content-center ion-align-items-center d-flex"
//-     :style="computedStyle"
//- )
//-     div(
//-         class="ion-justify-content-center ion-align-items-center d-flex white-fill"
//-         style="background-color: white"
//-     )
//-         img(
//-             class="check-icon"
//-             :class="checked ? 'checked' : ''"
//-             :src="imagePath"
//-         )
IonButton(
    class="check-button ion-justify-content-center ion-align-items-center d-flex round-btn"
    :style="computedStyle"
    color="white"
)
    div(class="ion-justify-content-center ion-align-items-center d-flex white-fill")
        img(
            class="check-icon"
            :class="checked ? 'checked' : ''"
            :src="imagePath"
        )
</template>

<script>
import { computed } from "vue"
export default {
    props: {
        size: {
            type: String,
            default: "45"
        },
        checked: {
            type: Boolean
        },
        fileName: {
            type: String,
            default: "check.png"
        }
    },

    setup(props) {
        const imagePath = computed(() => {
            return require(`@/assets/icon/${props.fileName}`)
        })
        const computedStyle = computed(() => {
            let style = `width: ${props.size}px; height: ${props.size}px;`
            return style
        })
        return { computedStyle, imagePath }
    }
}
</script>

<style lang="sass" scoped>
.check-button
    border: 4px solid var(--ion-color-tertiary)
    border-radius: 50%
    .white-fill
        // background-color: #fff
        border-radius: 50%
        width: 100%
        height: 100%
        .check-icon
            height: 80%
            opacity: 0
            &.checked
                opacity: 1
</style>