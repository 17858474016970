<template lang="pug">
div(class="d-flex flex-column align-center justify-center")
    img(
        src="@/assets/icon/check-big.png"
        height="80"
    )
    IonText(
        color="white"
        v-if="!!text"
        class="mt-3"
    )
        h1 {{ text }}
</template>

<script>
export default {
    props: {
        text: String
    }
}
</script>