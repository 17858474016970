<template lang="pug">
teleport(
    to="#modals"
)
    vue-final-modal(
        v-model="modalOpened"
        content-class="d-flex align-center justify-center"
    )
        slot
</template>

<script>
import { ref, watch } from "vue"
export default {
    props: ["modelValue"],
    setup(props, ctx) {
        const modalOpened = ref()

        watch(modalOpened, (val) => {
            ctx.emit(`update:modelValue`, val)
        })

        watch(
            () => props.modelValue,
            (val) => {
                modalOpened.value = val
            },
            { immediate: true }
        )
        return { modalOpened }
    }
}
</script>

<style lang="sass">
.vfm__content
    width: 100%
    height: 100%
// .vfm--overlay
//     background-color: #0A2A4D !important
//     opacity: .81
</style>
