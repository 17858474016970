import { reactive, readonly, ref, computed } from "vue";


// const state = reactive({
//     isLoggedIn: false
// });
const state = reactive({
    isLoggedIn: false
});

function logUserIn() {
    state.isLoggedIn = true
};

function logUserOut() {
    state.isLoggedIn = false
};

function saveUser() {
    localStorage.setItem('userToken', true)
};

function logUserInFromStorage() {
    let token = localStorage.getItem('userToken')
    // console.log(token)
    if (!!token) logUserIn()
}



export function useAuth() {
    return { isLoggedIn: computed(() => state.isLoggedIn), logUserIn, logUserOut, saveUser, logUserInFromStorage }
};