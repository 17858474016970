<template lang="pug">
div(
    style="position: fixed; bottom: 0; left: 0"
)
    //- step 1
    div(
        class="page-buttons-wrapper"
        v-if="$route.name == 'order-1' && currentOrder"
    )
        Container
            IonRow(class="receding btn-container")
                IonCol(
                    size="6"
                    class="pb-0"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="callOperator"
                    )
                        IonText(
                            color="primary"
                        )
                            h3(class="text-center") Einsatzzentrale kontaktieren

                IonCol(
                    size="6"
                    class="pb-0"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="openMap"
                    )
                        IonText(
                            color="primary"
                        )
                            h3(class="text-center") Navigation starten

                IonCol(
                    size="12"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="$router.push({ name: 'order-2', params: { id: currentOrder.id } })"
                    )
                        IonText(
                            color="primary"
                        )
                            h3(class="text-center") Angekommen

    //- step 2
    div(
        class="page-buttons-wrapper"
        v-if="$route.name == 'order-2' && currentOrder"
    )
        Container
            IonRow(class="receding btn-container")
                IonCol(
                    size="6"
                    class="pb-0"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="callOperator"
                    )
                        IonText(
                            color="primary"
                        )
                            h3(class="text-center") Einsatzzentrale kontaktieren

                IonCol(
                    size="6"
                    class="pb-0"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="openCancelByClientModal"
                    )
                        IonText(
                            color="primary"
                        )
                            h3(class="text-center") Abbruch

                IonCol(
                    size="12"
                )
                    //- IonButton(
                    //-     :color="step2NextBtn ? 'secondary' : 'accent'"
                    //-     :disabled="!step2NextBtn"
                    //-     size="large"
                    //-     expand="block"
                    //-     @click="$router.push({ name: 'order-3', params: { id: currentOrder.id } })"
                    //- )
                    //-     //- @click="allTermsModal = true"
                    //-     IonText(
                    //-         color="primary"
                    //-     )
                    //-         h3(class="text-center") Weiter

                    IonButton(
                        :color="step2NextBtn ? 'secondary' : 'accent'"
                        :disabled="!step2NextBtn"
                        size="large"
                        expand="block"
                        @click="$router.push({ name: 'order-inventory', params: { id: currentOrder.id } })"
                    )
                        IonText(
                            color="primary"
                        )
                            h3(class="text-center") Bestandsaufnahme

    //- //- step 3
    //- div(
    //-     class="page-buttons-wrapper"
    //-     v-if="$route.name == 'order-3' && currentOrder"
    //- )
    //-     Container
    //-         IonRow(class="receding btn-container")
    //-             //- IonCol(
    //-             //-     size="12"
    //-             //-     class="pb-0"
    //-             //- )
    //-             //-     IonButton(
    //-             //-         color="secondary"
    //-             //-         size="large"
    //-             //-         expand="block"
    //-             //-         @click="$router.push({ name: 'order-inventory', params: { id: currentOrder.id } })"
    //-             //-     )
    //-             //-         IonText(
    //-             //-             color="primary"
    //-             //-         )
    //-             //-             h3(class="text-center") Bestandsaufnahme

    //-             //- IonCol(
    //-             //-     size="12"
    //-             //- )
    //-             //-     IonButton(
    //-             //-         size="large"
    //-             //-         expand="block"
    //-             //-         @click="$router.push({ name: 'order-4', params: { id: currentOrder.id } })"
    //-             //-         :disabled="!inventoryDone"
    //-             //-         :color="inventoryDone ? 'secondary' : 'accent'"
    //-             //-     )
    //-             //-         IonText(
    //-             //-             color="primary"
    //-             //-         )
    //-             //-             h3 Weiter

    //-             IonCol(
    //-                 size="12"
    //-                 class="pb-0"
    //-             )
    //-                 IonButton(
    //-                     color="secondary"
    //-                     size="large"
    //-                     expand="block"
    //-                     @click="$router.push({ name: 'order-inventory', params: { id: currentOrder.id } })"
    //-                 )
    //-                     IonText(
    //-                         color="primary"
    //-                     )
    //-                         h3(class="text-center") Bestandsaufnahme

    //- order inventory
    div(
        class="page-buttons-wrapper"
        v-if="$route.name == 'order-inventory' && currentOrder"
    )
        Container
            IonRow(class="receding btn-container")
                IonCol(
                    size="12"
                )
                    //- if there are any videos, pics or notes
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="$router.push({ name: 'order-inventory-report', params: { id: currentOrder.id } })"
                        v-if="orderGotVidOrImgOrNote"
                    )
                        IonText(
                            color="primary"
                        )
                            h3 Bericht Anzeigen

                    //- if there are no videos, pics or notes
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        v-if="!orderGotVidOrImgOrNote"
                        @click="$router.push({ name: 'order-5', params: { id: currentOrder.id } })"
                    )
                        //- @click="addInventoryToDone(currentOrder.id), $router.push({ name: 'order-3', params: { id: currentOrder.id } })"
                        IonText(
                            color="primary"
                        )
                            h3 Arbeiten starten

    //- order inventory report
    div(
        class="page-buttons-wrapper"
        v-if="$route.name == 'order-inventory-report' && currentOrder"
    )
        Container
            IonRow(class="receding")
                IonCol(
                    size="6"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="openCancelByClientModal"
                    )
                        IonText(
                            color="primary"
                        )
                            h3 Abbruch durch Kunden

                IonCol(
                    size="6"
                )
                    IonButton(
                        :disabled="!orderInventoryReportNextBtn"
                        size="large"
                        expand="block"
                        color="secondary"
                        :style="!orderInventoryReportNextBtn ? { opacity: '0.3' } : {}"
                        @click="$router.push({ name: 'order-5', params: { id: currentOrder.id } })"
                    )
                        //- @click="addInventoryToDone(currentOrder.id), $router.push({ name: 'order-3', params: { id: currentOrder.id } })"
                        IonText(
                            color="primary"
                        )
                            h3 Arbeiten starten
                //- IonCol(
                //-     size="6"
                //- )
                //-     IonButton(
                //-         :disabled="!orderInventoryReportNextBtn"
                //-         size="large"
                //-         expand="block"
                //-         @click="addInventoryToDone(currentOrder.id), $router.push({ name: 'order-3', params: { id: currentOrder.id } })"
                //-         color="secondary"
                //-         :style="!orderInventoryReportNextBtn ? { opacity: '0.3' } : {}"
                //-     )
                //-         IonText(
                //-             color="primary"
                //-         )
                //-             h3 Arbeiten starten

    //- step 4
    div(
        class="page-buttons-wrapper"
        v-if="$route.name == 'order-4' && currentOrder"
    )
        Container
            IonRow(class="receding")
                IonCol(
                    size="12"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="$router.push({ name: 'order-5', params: { id: currentOrder.id } })"
                    )
                        IonText(
                            color="primary"
                        )
                            h3(class="text-center") Auftrag starten

    //- step 5 (stopwatch)
    div(
        class="page-buttons-wrapper"
        v-if="$route.name == 'order-5' && currentOrder"
    )
        Container
            IonRow(class="receding")
                IonCol(
                    size="12"
                    class="pb-0"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="callOperator"
                    )
                        IonText(
                            color="primary"
                        )
                            h3 Einsatzzentrale kontaktieren
                IonCol(
                    size="6"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="toggleStopwatch"
                    )
                        IonText(
                            color="primary"
                        )
                            h3
                                span(
                                    v-if="!!stopwatchIsCountingBtn"
                                ) Pause
                                span(
                                    v-else
                                ) Start

                IonCol(
                    size="6"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="$router.push({ name: 'order-6', params: { id: currentOrder.id } })"
                    )
                        IonText(
                            color="primary"
                        )
                            h3 Ende

    //- step 6
    div(
        class="page-buttons-wrapper"
        v-if="$route.name == 'order-6' && currentOrder"
    )
        Container
            IonRow(class="receding")
                IonCol(
                    size="12"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="$router.push({ name: 'order-report', params: { id: currentOrder.id } })"
                    )
                        IonText(
                            color="primary"
                        )
                            h3 Weiter zum Rapport

    //- step 7
    div(
        class="page-buttons-wrapper"
        v-if="$route.name == 'order-7' && currentOrder"
    )
        Container
            IonRow(class="receding")
                IonCol(
                    size="12"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="$router.push({ name: 'order-report', params: { id: currentOrder.id } })"
                    )
                        IonText(
                            color="primary"
                        )
                            h3 Rapport anzeigen

    //- report
    div(
        class="page-buttons-wrapper"
        v-if="$route.name == 'order-report' && currentOrder"
    )
        Container
            IonRow(class="receding")
                IonCol(
                    size="6"
                    class="pb-0"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="callOperator"
                    )
                        IonText(
                            color="primary"
                        )
                            h3(class="text-center") Einsatzzentrale kontaktieren

                IonCol(
                    size="6"
                    class="pb-0"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="openDefinitiveCancelByClientModal"
                    )
                        IonText(
                            color="primary"
                        )
                            h3(class="text-center") Abbruch
                IonCol(
                    size="12"
                )
                    IonButton(
                        size="large"
                        expand="block"
                        :disabled="!orderReportNextBtn"
                        color="secondary"
                        :style="!orderReportNextBtn ? { opacity: '0.3' } : {}"
                        @click="openReportSendModal"
                    )
                        IonText(
                            color="primary"
                        )
                            h3 Weiter zur Zahlung

    //- payment
    div(
        class="page-buttons-wrapper"
        v-if="$route.name == 'order-payment' && currentOrder"
    )
        Container
            Spacer(
                small
            )
            IonRow(class="receding")
                IonCol(
                    size="6"
                    class="d-flex flex-column"
                )
                    div(
                        style="background-color: var(--ion-color-primary); padding-top: 10px; height: 100%"
                        class="radius"
                    )
                        IonText(
                            color="quaternary"
                            class="d-flex flex-column justify-center align-center"
                            style="height: 100%; width: 100%"
                        )
                            div(
                                style="padding: 0 10px 10px; border-bottom: 1px dashed var(--ion-color-quaternary); width: 100%"
                            ) Rechnungsbetrag inklusive MwSt.
                            div(
                                class="d-flex align-center"
                                style="flex-grow: 1; width: 100%"
                            )
                                div(
                                    class="d-flex align-start"
                                    style="padding: 20px 10px 20px"
                                )
                                    div(
                                        style="font-size: 25px"
                                        class="mr-2"
                                    ) €
                                    div(
                                        style="font-size: 40px"
                                    ) {{ currentOrder.doctotal.toFixed(2) }}

                IonCol(
                    size="6"
                    class="d-flex flex-column"
                )
                    div(
                        style="background-color: var(--ion-color-quaternary); padding-top: 10px; height: 100%"
                        class="radius"
                    )
                        IonText(
                            color="primary"
                        )
                            div(
                                style="padding: 0 10px 10px; border-bottom: 1px dashed var(--ion-color-primary)"
                            ) Zahlart wählen
                            div(
                                style="padding: 20px 5px 20px"
                            )
                                IonRadioGroup(
                                    :value="paymentMethod"
                                )
                                    div(class="d-flex align-center mb-3")
                                        IonRadio(
                                            value="ec"
                                            mode="md"
                                            :disabled="paymentSuccess"
                                        )
                                        h3(
                                            style="font-size: 14px"
                                            class="ml-2"
                                        ) Kartenzahlung

                                    div(class="d-flex align-center mb-3")
                                        IonRadio(
                                            value="cash"
                                            mode="md"
                                            :disabled="paymentSuccess"
                                        )
                                        h3(
                                            style="font-size: 14px"
                                            class="ml-2"
                                        ) Barzahlung

                                    //- div(class="d-flex align-center")
                                    //-     IonRadio(
                                    //-         value="invoice"
                                    //-         mode="md"
                                    //-         :disabled="true"
                                    //-     )
                                    //-         //- :disabled="paymentSuccess"
                                    //-     h3(class="ml-2") Rechnung

            IonRow(class="receding btn-container")
                IonCol(
                    size="12"
                    class="pb-0"
                    v-if="!paymentSuccess"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="pay"
                    )
                        IonText(
                            color="primary"
                        )
                            h3(class="text-center") Jetzt bezahlen
                IonCol(
                    size="12"
                    class="pb-0"
                    v-if="!!paymentSuccess"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="openReceiptSendModal"
                    )
                        IonText(
                            color="white"
                        )
                            h3(class="text-center") Beleg per e-mail senden
                IonCol(
                    size="12"
                    class="pb-0"
                    v-if="!!paymentSuccess"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                    )
                        IonText(
                            color="white"
                        )
                            h3(class="text-center") Beleg drucken
                IonCol(
                    size="12"
                    class="pb-0"
                    v-if="!!paymentSuccess"
                )
                    IonButton(
                        color="secondary"
                        size="large"
                        expand="block"
                        @click="finishOrder"
                    )
                        IonText(
                            color="primary"
                        )
                            h3(class="text-center") Auftrag abschliessen

    div(
        class="page-buttons-wrapper pt-6"
        style="background-color: var(--ion-color-primary)"
        v-if="$route.name == 'time-recording'"
    )
        Timelines

    div(
        class="page-buttons-wrapper"
        v-if="$route.name == 'messenger-id'"
        style="border-radius: 0"
    )
        div(
            style="position: relative"
        )
            Container
                IonTextarea(
                    auto-grow
                    style="padding-right: 40px"
                )
            IonButton(
                class="round-btn"
                color="secondary"
                @click="sendPrivMsg"
                style="width: 45px; height: 45px; position: absolute; right: 8px; bottom: 2px; z-index: 2"
            )
                IonIcon(
                    color="white"
                    :icon="chevronForwardCircle"
                    style="width: 100%; height: 100%"
                )

    //- cancel by client modal
    CustomModal(
        v-model="cancelByClientModal"
    )
        ConfirmModalContentWithSlot(
            @confirm="cancelByClient"
            @decline="closeCancelByClientModal"
            declineText="Zurück"
            secondVariant
        )
            div(
                class="d-flex flex-column"
                style="height: 100%"
            )
                Spacer
                IonTextarea(
                    style="flex-grow: 1; --background: white"
                    v-model="cancelCauseText"
                    class="radius"
                )

    //- definitive cancel by client modal
    CustomModal(
        v-model="definitiveCancelByClientModal"
    )
        ConfirmModalContentWithSlot(
            @confirm="definitiveCancelByClient"
            @decline="closeDefinitiveCancelByClientModal"
            declineText="Zurück"
            secondVariant
        )
            div(
                class="d-flex flex-column"
                style="height: 100%"
            )
                Spacer
                IonTextarea(
                    style="flex-grow: 1; --background: white; color: black"
                    v-model="cancelationReason"
                    class="radius"
                )
</template>

<script>
import { useUtilities } from "@/composables/utilities"
import { useBtnController } from "@/composables/buttons-controller"
import { useState } from "@/composables/state"
import { computed, ref } from "vue"
import { useRouter } from "vue-router"
import { chevronForwardCircle } from "ionicons/icons"
import Timelines from "@/components/sections/Timelines"
import CustomModal from "@/components/molecules/modals/CustomModal"
import ConfirmModalContentWithSlot from "@/components/molecules/modals/ConfirmModalContentWithSlot"
import ConfirmModalContent from "@/components/molecules/modals/ConfirmModalContent"

export default {
    components: {
        Timelines,
        CustomModal,
        ConfirmModalContentWithSlot,
        ConfirmModalContent
    },

    setup() {
        chevronForwardCircle
        const router = useRouter()
        const { currentOrder } = useUtilities()
        const { markOrderAsDone, sampleMapData, sampleOperator } = useState()

        const {
            step2NextBtn,
            doneInventories,
            orderInventoryReportNextBtn,
            addInventoryToDone,
            stopwatchIsCountingBtn,
            orderReportNextBtn
        } = useBtnController()

        function callOperator() {
            console.log(sampleOperator)
            window.open(`tel:${sampleOperator.phone}`, "_blank")
        }

        // bus
        const emitter = useUtilities().getEmitter()

        const inventoryDone = computed(() => {
            return doneInventories.includes(currentOrder.value.id)
        })

        function toggleStopwatch() {
            !!stopwatchIsCountingBtn.value
                ? emitter.emit("setStopwatch", false)
                : emitter.emit("setStopwatch", true)
        }

        function openReportSendModal() {
            emitter.emit("openReportSendModal")
        }

        const orderGotVidOrImgOrNote = computed(() => {
            return (
                !!currentOrder.value?.videos?.length ||
                !!currentOrder.value?.images?.length ||
                !!currentOrder.value?.remarks
            )
        })

        // cancel by client

        const cancelationReason = ref()

        const cancelByClientModal = ref(false)
        function openCancelByClientModal() {
            cancelByClientModal.value = true
        }

        function closeCancelByClientModal() {
            cancelByClientModal.value = false
        }

        function cancelByClient() {
            router.push({
                name: "order-report",
                params: { id: currentOrder.value.id }
            })
        }
        const cancelCauseText = ref()

        // definitive cancel by client
        const definitiveCancelByClientModal = ref(false)

        function openDefinitiveCancelByClientModal() {
            definitiveCancelByClientModal.value = true
        }
        function closeDefinitiveCancelByClientModal() {
            definitiveCancelByClientModal.value = false
        }

        function definitiveCancelByClient() {
            router.push({
                name: "order-processing"
            })
        }

        // payment
        const paymentMethod = ref("cash")
        const paymentSuccess = ref()

        function pay() {
            emitter.emit("setLoadingModal", true)
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal", "Bezahlt")
            }, 1000)
            setTimeout(() => {
                paymentSuccess.value = true
            }, 1000)
        }

        function finishOrder() {
            markOrderAsDone(currentOrder.value.id)
            router.push({ name: "home" })
        }

        function openReceiptSendModal() {
            emitter.emit("openReceiptSendModal")
        }

        // step 1 - map
        function openMap() {
            window.open(
                `https://maps.google.com/?q=${sampleMapData.lat},${sampleMapData.lng}`,

                "_blank"
            )
        }

        // priv messenger
        function sendPrivMsg() {
            console.log("wysylam")
        }

        return {
            currentOrder,
            step2NextBtn,
            inventoryDone,
            doneInventories,
            orderInventoryReportNextBtn,
            addInventoryToDone,
            stopwatchIsCountingBtn,
            toggleStopwatch,
            orderReportNextBtn,
            openReportSendModal,
            paymentMethod,
            paymentSuccess,
            finishOrder,
            pay,
            openReceiptSendModal,
            chevronForwardCircle,
            sendPrivMsg,
            orderGotVidOrImgOrNote,
            cancelByClientModal,
            openCancelByClientModal,
            closeCancelByClientModal,
            cancelByClient,
            openMap,
            callOperator,
            cancelCauseText,
            openDefinitiveCancelByClientModal,
            closeDefinitiveCancelByClientModal,
            definitiveCancelByClient,
            definitiveCancelByClientModal,
            cancelationReason
        }
    }
}
</script>