import { reactive, readonly, computed, ref, watch } from "vue";



const sampleOrders2 = [
    {
        "@context": "/contexts/Order",
        "@id": "/orders/120",
        "@type": "Order",
        "id": 120,
        "customer": {
            "@id": "/customers/18267",
            "@type": "Customer",
            "id": 18267,
            "matchcode": null,
            "referencenumber": null,
            "cardtype": "C",
            "gender": "Firma",
            "company": "Mustermann GmbH ",
            "company2": null,
            "company3": null,
            "firstname": "Max",
            "lastname": "Mustermann",
            "street": "Musterstraße 1",
            "zipcode": "70794",
            "city": "Filderstadt",
            "phone": "07116655823",
            "mobile": "",
            "fax": "",
            "billtogender": "Firma",
            "billtocompany": "Mustermann GmbH ",
            "billtocompany2": null,
            "billtocompany3": null,
            "billtofirstname": "Max",
            "billtolastname": "Mustermann",
            "billtostreet": "Musterstraße 1",
            "billtozipcode": "70794",
            "billtocity": "Filderstadt",
            "billtophone": "07116655823",
            "billtomobile": "",
            "billtofax": "",
            "billtoemail": "mustermann@web.de",
            "provisioncardid": null,
            "provisionemployeeid": null,
            "provisioncardvalue": 0,
            "provisionemployeevalue": 0,
            "paymentmethod": null,
            "pricelistnum": null,
            "provisionreceiver": false,
            "provisionreceivepercent": null,
            "provisionreceiveduration": null,
            "discount": 0,
            "provisionemployee2id": null,
            "provisionemployee2value": 0,
            "debitorno": null,
            "email": "mustermann@web.de",
            "comments": null,
            "customeroutdated": 0,
            "blocked": 0,
            "blockreason": null,
            "rating": 0,
            "disposalsite": false,
            "free1": null,
            "free2": null,
            "free3": null,
            "free4": 0,
            "free5": 0,
            "free6": "0001-01-01T01:00:00+00:00",
            "createts": "0001-01-01T01:00:00+00:00"
        },
        "contactid": null,
        "objectid": 33580,
        "docnum": 0,
        "rating": null,
        "docdate": "2020-10-21T16:11:32+00:00",
        "deliverdate": "2020-10-21T02:00:00+00:00",
        "delivertimefrom": "2020-10-21T14:00:00+00:00",
        "delivertimeto": "2020-10-21T16:00:00+00:00",
        "orderdate": "2020-10-21T16:11:32+00:00",
        "completedate": "2020-10-21T15:50:35+00:00",
        "completedby": "/users/2",
        "requirements": "1",
        "turnuscomponents": null,
        "canceled": false,
        "duration": 60,
        "owner": 2,
        "cause": "Vorlage verstopfte Spüle",
        "completed": 0,
        "confirmed": true,
        "confirmationbymail": true,
        "confirmationbyfax": false,
        "confirmationonsite": false,
        "confirmedby": null,
        "confirmationsent": null,
        "confirmeddate": null,
        "ordersource": 4,
        "deliverycanceled": false,
        "deliverycanceledcause": "",
        "orderto": 0,
        "priority": 0,
        "fixedappointment": false,
        "comment": "Hier ist der Bereich der vermerke ",
        "externalremark": "fbgdfgdf",
        "inprogress": 0,
        "remarks": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
        "paymethod": 1,
        "ordercategory": null,
        "callercompany": "",
        "callercompany2": "",
        "callercompany3": "",
        "callergender": "Frau",
        "callerfirstname": "Sabine",
        "callerlastname": "Musterman",
        "callerstreet": "Musterstr. 1",
        "callerzipcode": "71234",
        "callercity": "Stuttgart",
        "callerphone": "0157123456",
        "callermobile": "",
        "callerfax": "",
        "calleremail": "",
        "locationcompany": "Mustermann GmbH ",
        "locationcompany2": "",
        "locationcompany3": "",
        "locationgender": "Firma",
        "locationfirstname": "Max",
        "locationlastname": "Mustermann",
        "locationstreet": "Musterstraße 1",
        "locationunit": "",
        "locationzipcode": "70794",
        "locationcity": "Filderstadt",
        "locationphone": "07116655823",
        "locationmobile": "",
        "locationfax": "",
        "locationemail": "mustermann@web.de",
        "locationcontactgender": "Frau",
        "locationcontactfirstname": "Mara",
        "locationcontactlastname": "Mustermann",
        "locationcontactphone": "01749655325",
        "invoicecompany": "Mustermann GmbH ",
        "invoicecompany2": "",
        "invoicecompany3": "",
        "invoicegender": "Firma",
        "invoicefirstname": "Max",
        "invoicelastname": "Mustermann",
        "invoicestreet": "Musterstraße 1",
        "invoicezipcode": "70794",
        "invoicecity": "Filderstadt",
        "invoicephone": "07116655823",
        "invoicemobile": "",
        "invoicefax": "",
        "invoiceemail": "mustermann@web.de",
        "housecontactname": null,
        "housecontactphone": null,
        "entrytext": null,
        "conditions": null,
        "departurelatitude": 0,
        "departurelongitude": 0,
        "departuretime": "2020-10-21T16:23:28+00:00",
        "arrivaltime": "2020-10-21T16:23:28+00:00",
        "starttime": "2020-10-21T16:23:28+00:00",
        "endtime": null,
        "doctotal": 216.6,
        "docdiscount": 0,
        "docvat": 41.154,
        "free1": null,
        "multiday": false,
        "free2": null,
        "free3": null,
        "free4": null,
        "free5": null,
        "free6": null,
        "provisioncardid": null,
        "provisioncardvalue": null,
        "wiederrufsbelehrung": 0,
        "exported": false,
        "invoiceid": 0,
        "lines": [
            {
                "@id": "/order_lines/415",
                "@type": "OrderLine",
                "id": 415,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T01:00:00+00:00",
                "dateto": "0001-01-01T01:00:00+00:00",
                "itemid": 27,
                "positiontext": "Vorlage verstopfte Spüle",
                "unitprice": 0,
                "quantity": 1,
                "uom": null,
                "discount": 0,
                "linesum": 0,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            },
            {
                "@id": "/order_lines/416",
                "@type": "OrderLine",
                "id": 416,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T01:00:00+00:00",
                "dateto": "0001-01-01T01:00:00+00:00",
                "itemid": 25,
                "positiontext": "[ - ] 1. Monteur",
                "unitprice": 4.6,
                "quantity": 12,
                "uom": "AW",
                "discount": 0,
                "linesum": 55.2,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            },
            {
                "@id": "/order_lines/417",
                "@type": "OrderLine",
                "id": 417,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T01:00:00+00:00",
                "dateto": "0001-01-01T01:00:00+00:00",
                "itemid": 22,
                "positiontext": "[ - ] 2. Monteur",
                "unitprice": 3.7,
                "quantity": 12,
                "uom": "AW",
                "discount": 0,
                "linesum": 44.4,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            },
            {
                "@id": "/order_lines/418",
                "@type": "OrderLine",
                "id": 418,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T01:00:00+00:00",
                "dateto": "0001-01-01T01:00:00+00:00",
                "itemid": 21,
                "positiontext": "[ - ] An-/Abfahrt",
                "unitprice": 39.5,
                "quantity": 1,
                "uom": "Pauschale",
                "discount": 0,
                "linesum": 39.5,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            },
            {
                "@id": "/order_lines/419",
                "@type": "OrderLine",
                "id": 419,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T01:00:00+00:00",
                "dateto": "0001-01-01T01:00:00+00:00",
                "itemid": 7,
                "positiontext": "[ - ] Geräteeinsatzwagen",
                "unitprice": 77.5,
                "quantity": 1,
                "uom": "Std",
                "discount": 0,
                "linesum": 77.5,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            }
        ],
        "vehicles": [
            {
                "@id": "/order_vehicles/97",
                "@type": "OrderVehicle",
                "id": 97,
                "date": "2020-10-21T06:00:00+00:00",
                "datefrom": "2020-10-21T15:10:00+00:00",
                "dateto": "2020-10-21T16:10:00+00:00",
                "deleted": false,
                "lastupdate": "-0001-11-30T00:00:00+00:00",
                "vehicle": {
                    "@id": "/vehicles/184",
                    "@type": "Vehicle",
                    "id": 184,
                    "name": "ES-SY-016",
                    "licenseplate": "ES-SY-016",
                    "insurancecontract": "",
                    "make": "Iveco",
                    "model": "Daily",
                    "color": "",
                    "rating": 0,
                    "vin": "",
                    "group": {
                        "@id": "/groups/2",
                        "@type": "Group",
                        "id": 2,
                        "groupname": "Rohrreiniger",
                        "useinplanner": true
                    },
                    "requirements": "",
                    "maintenancetype": null,
                    "gpstrackingid": 10721,
                    "insurancecompany": "",
                    "insurancenumber": "",
                    "insurancevk": 0,
                    "insurancetk": 0,
                    "insurancehp": 0,
                    "insurancevksb": 0,
                    "insurancetksb": 0,
                    "insurancehpsb": 0,
                    "insurancevksf": 0,
                    "insurancetksf": 0,
                    "insurancehpsf": 0,
                    "schutzbrief": 0,
                    "nexthu": null,
                    "nextau": null,
                    "nextsp": null,
                    "nextuvv": null,
                    "leasingcompany": "UVW",
                    "leasingnumber": "",
                    "leasingstart": null,
                    "leasingend": null,
                    "listprice": 0,
                    "leasingcost": 1441,
                    "freekilometer": 0,
                    "costkilometer": 0,
                    "refundkilometer": 0,
                    "leasingcontract": "",
                    "remarks": "",
                    "imagepath": "",
                    "employee": ""
                },
                "employee": null,
                "user": null,
                "employees": []
            }
        ],
        "notes": [],
        "rapports": [],
        "invoices": [],
        "attachments": [],
        videos: [
            {
                name: "v1-210120-11.30",
                id: "1",
                thumb: require("@/assets/temp/1.jpeg")
            },
            {
                name: "v2-210120-11.37",
                id: "2",
                thumb: require("@/assets/temp/2.jpeg")
            },
            {
                name: "v2-210120-13.37",
                id: "3",
                thumb: require("@/assets/temp/3.jpeg")
            }
        ],
        images: [
            {
                name: "i1-210120-11.30",
                id: "3",
                thumb: require("@/assets/temp/3.jpeg")
            },
            {
                name: "i2-210120-11.37",
                id: "4",
                thumb: require("@/assets/temp/4.jpeg")
            },
            {
                name: "i3-210120-11.37",
                id: "5",
                thumb: require("@/assets/temp/5.jpeg")
            }
        ]
    },
    {
        "@context": "/contexts/Order",
        "@id": "/orders/130",
        "@type": "Order",
        "id": 130,
        "customer": {
            "@id": "/customers/613",
            "@type": "Customer",
            "id": 613,
            "matchcode": "Lieferant",
            "referencenumber": "",
            "cardtype": "s",
            "gender": "Firma",
            "company": "Wahl Sanitär GmbH",
            "company2": "",
            "company3": "",
            "firstname": "",
            "lastname": "",
            "street": "Unter dem Birkenkopf 23",
            "zipcode": "70197",
            "city": "Stuttgart",
            "phone": "0711/65659-0",
            "mobile": "",
            "fax": "0711/65659-22",
            "billtogender": "Firma",
            "billtocompany": "Wahl Sanitär GmbH",
            "billtocompany2": "",
            "billtocompany3": "",
            "billtofirstname": "",
            "billtolastname": "",
            "billtostreet": "Unter dem Birkenkopf 23",
            "billtozipcode": "70197",
            "billtocity": "Stuttgart",
            "billtophone": "0711/65659-0",
            "billtomobile": "",
            "billtofax": "0711/65659-22",
            "billtoemail": "",
            "provisioncardid": 0,
            "provisionemployeeid": 0,
            "provisioncardvalue": 0,
            "provisionemployeevalue": 0,
            "paymentmethod": 1,
            "pricelistnum": 1,
            "provisionreceiver": false,
            "provisionreceivepercent": 0,
            "provisionreceiveduration": 0,
            "discount": 0,
            "provisionemployee2id": 0,
            "provisionemployee2value": 0,
            "debitorno": "94877",
            "email": "",
            "comments": "Kd.Nr.122006",
            "customeroutdated": 0,
            "blocked": 0,
            "blockreason": "",
            "rating": 0,
            "disposalsite": false,
            "free1": null,
            "free2": null,
            "free3": null,
            "free4": 0,
            "free5": 0,
            "free6": "0001-01-02T19:00:00+00:00",
            "createts": "0001-01-02T19:00:00+00:00"
        },
        "contactid": null,
        "objectid": 33560,
        "docnum": 0,
        "rating": null,
        "docdate": "2020-11-24T11:37:35+00:00",
        "deliverdate": "2020-11-24T00:00:00+00:00",
        "delivertimefrom": "2020-11-24T10:00:00+00:00",
        "delivertimeto": "2020-11-24T12:00:00+00:00",
        "orderdate": "2020-11-24T11:37:35+00:00",
        "completedate": "2020-12-01T00:00:00+00:00",
        "completedby": null,
        "requirements": "1,6",
        "turnuscomponents": null,
        "canceled": false,
        "duration": 120,
        "owner": 2,
        "cause": "Vorlage verstopftes WC",
        "completed": 0,
        "confirmed": false,
        "confirmationbymail": true,
        "confirmationbyfax": false,
        "confirmationonsite": false,
        "confirmedby": null,
        "confirmationsent": null,
        "confirmeddate": null,
        "ordersource": 4,
        "deliverycanceled": false,
        "deliverycanceledcause": null,
        "orderto": 0,
        "priority": 4,
        "fixedappointment": false,
        "comment": "Damentoilette verstopft ",
        "externalremark": null,
        "inprogress": 0,
        "remarks": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
        "paymethod": 1,
        "ordercategory": 1,
        "callercompany": "",
        "callercompany2": "",
        "callercompany3": "",
        "callergender": "Frau",
        "callerfirstname": "Natalie",
        "callerlastname": "Blum",
        "callerstreet": "",
        "callerzipcode": "",
        "callercity": "Stuttgart",
        "callerphone": "0711 - 73734316",
        "callermobile": "",
        "callerfax": "",
        "calleremail": "",
        "locationcompany": "Wahl Sanitär GmbH",
        "locationcompany2": "",
        "locationcompany3": "",
        "locationgender": "Firma",
        "locationfirstname": "",
        "locationlastname": "",
        "locationstreet": "Unter dem Birkenkopf 16",
        "locationunit": "EG",
        "locationzipcode": "70197",
        "locationcity": "Stuttgart",
        "locationphone": "",
        "locationmobile": "",
        "locationfax": "",
        "locationemail": "",
        "locationcontactgender": "Herr",
        "locationcontactfirstname": "Hans ",
        "locationcontactlastname": "Kohler",
        "locationcontactphone": "0175-9966532",
        "invoicecompany": "Wahl Sanitär GmbH",
        "invoicecompany2": "",
        "invoicecompany3": "",
        "invoicegender": "Firma",
        "invoicefirstname": "",
        "invoicelastname": "",
        "invoicestreet": "Unter dem Birkenkopf 23",
        "invoicezipcode": "70197",
        "invoicecity": "Stuttgart",
        "invoicephone": "0711/65659-0",
        "invoicemobile": "",
        "invoicefax": "0711/65659-22",
        "invoiceemail": "",
        "housecontactname": null,
        "housecontactphone": null,
        "entrytext": null,
        "conditions": null,
        "departurelatitude": 0,
        "departurelongitude": 0,
        "departuretime": "2020-11-24T11:52:51+00:00",
        "arrivaltime": "2020-11-24T11:52:51+00:00",
        "starttime": "2020-11-24T11:52:51+00:00",
        "endtime": null,
        "doctotal": 256.1,
        "docdiscount": 0,
        "docvat": 48.659,
        "free1": null,
        "multiday": false,
        "free2": null,
        "free3": null,
        "free4": null,
        "free5": null,
        "free6": null,
        "provisioncardid": null,
        "provisioncardvalue": null,
        "wiederrufsbelehrung": 0,
        "exported": false,
        "invoiceid": 0,
        "lines": [
            {
                "@id": "/order_lines/446",
                "@type": "OrderLine",
                "id": 446,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T00:00:00+00:00",
                "dateto": "0001-01-01T00:00:00+00:00",
                "itemid": 28,
                "positiontext": "Vorlage verstopftes WC",
                "unitprice": 0,
                "quantity": 1,
                "uom": null,
                "discount": 0,
                "linesum": 0,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            },
            {
                "@id": "/order_lines/447",
                "@type": "OrderLine",
                "id": 447,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T00:00:00+00:00",
                "dateto": "0001-01-01T00:00:00+00:00",
                "itemid": 7,
                "positiontext": "[ - ] Geräteeinsatzwagen",
                "unitprice": 77.5,
                "quantity": 1,
                "uom": "Std",
                "discount": 0,
                "linesum": 77.5,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            },
            {
                "@id": "/order_lines/448",
                "@type": "OrderLine",
                "id": 448,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T00:00:00+00:00",
                "dateto": "0001-01-01T00:00:00+00:00",
                "itemid": 21,
                "positiontext": "[ - ] An-/Abfahrt",
                "unitprice": 39.5,
                "quantity": 1,
                "uom": "Pauschale",
                "discount": 0,
                "linesum": 39.5,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            },
            {
                "@id": "/order_lines/449",
                "@type": "OrderLine",
                "id": 449,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T00:00:00+00:00",
                "dateto": "0001-01-01T00:00:00+00:00",
                "itemid": 25,
                "positiontext": "[ - ] 1. Monteur",
                "unitprice": 4.6,
                "quantity": 12,
                "uom": "AW",
                "discount": 0,
                "linesum": 55.2,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            },
            {
                "@id": "/order_lines/450",
                "@type": "OrderLine",
                "id": 450,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T00:00:00+00:00",
                "dateto": "0001-01-01T00:00:00+00:00",
                "itemid": 22,
                "positiontext": "[ - ] 2. Monteur",
                "unitprice": 3.7,
                "quantity": 12,
                "uom": "AW",
                "discount": 0,
                "linesum": 44.4,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            },
            {
                "@id": "/order_lines/451",
                "@type": "OrderLine",
                "id": 451,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T00:00:00+00:00",
                "dateto": "0001-01-01T00:00:00+00:00",
                "itemid": 21,
                "positiontext": "An-/Abfahrt",
                "unitprice": 39.5,
                "quantity": 1,
                "uom": "Pauschale",
                "discount": 0,
                "linesum": 39.5,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            }
        ],
        "vehicles": [],
        "notes": [],
        "rapports": [],
        "invoices": [],
        "attachments": [],
        videos: [
            {
                name: "v1-210120-11.30",
                id: "1",
                thumb: require("@/assets/temp/1.jpeg")
            },
            {
                name: "v2-210120-11.37",
                id: "2",
                thumb: require("@/assets/temp/2.jpeg")
            },
            {
                name: "v2-210120-13.37",
                id: "3",
                thumb: require("@/assets/temp/3.jpeg")
            }
        ],
        images: [
            {
                name: "i1-210120-11.30",
                id: "3",
                thumb: require("@/assets/temp/3.jpeg")
            },
            {
                name: "i2-210120-11.37",
                id: "4",
                thumb: require("@/assets/temp/4.jpeg")
            },
            {
                name: "i3-210120-11.37",
                id: "5",
                thumb: require("@/assets/temp/5.jpeg")
            }
        ]
    },
    {
        done: false,
        "@context": "/contexts/Order",
        "@id": "/orders/140",
        "@type": "Order",
        "id": 140,
        "customer": {
            "@id": "/customers/18235",
            "@type": "Customer",
            "id": 18235,
            "matchcode": null,
            "referencenumber": null,
            "cardtype": "c",
            "gender": "Firma",
            "company": "Sanitär Wahl GmbH ",
            "company2": null,
            "company3": null,
            "firstname": "Harald",
            "lastname": "Wahl ",
            "street": "Unter dem Birkenkopf 23",
            "zipcode": "70193",
            "city": "Stuttgart",
            "phone": "0711-656590",
            "mobile": "",
            "fax": "",
            "billtogender": "Firma",
            "billtocompany": "Sanitär Wahl GmbH ",
            "billtocompany2": null,
            "billtocompany3": null,
            "billtofirstname": "Harald",
            "billtolastname": "Wahl ",
            "billtostreet": "Unter dem Birkenkopf 23",
            "billtozipcode": "70193",
            "billtocity": "Stuttgart",
            "billtophone": "0711-656590",
            "billtomobile": "",
            "billtofax": "",
            "billtoemail": "nataliewahl@web.de",
            "provisioncardid": null,
            "provisionemployeeid": null,
            "provisioncardvalue": 0,
            "provisionemployeevalue": 0,
            "paymentmethod": 1,
            "pricelistnum": 1,
            "provisionreceiver": false,
            "provisionreceivepercent": null,
            "provisionreceiveduration": null,
            "discount": 0,
            "provisionemployee2id": null,
            "provisionemployee2value": 0,
            "debitorno": null,
            "email": "nataliewahl@web.de",
            "comments": null,
            "customeroutdated": 0,
            "blocked": 0,
            "blockreason": null,
            "rating": 0,
            "disposalsite": false,
            "free1": null,
            "free2": null,
            "free3": null,
            "free4": 0,
            "free5": 0,
            "free6": "0001-01-01T05:00:00+00:00",
            "createts": "0001-01-01T05:00:00+00:00"
        },
        "contactid": null,
        "objectid": 33573,
        "docnum": 0,
        "rating": null,
        "docdate": "2021-02-22T14:48:17+00:00",
        "deliverdate": "2021-02-22T00:00:00+00:00",
        "delivertimefrom": null,
        "delivertimeto": null,
        "orderdate": "2021-02-22T14:48:17+00:00",
        "completedate": "2021-03-01T00:00:00+00:00",
        "completedby": null,
        "requirements": "",
        "turnuscomponents": null,
        "canceled": false,
        "duration": 0,
        "owner": 2,
        "cause": "Vorlage verstopfte Spüle",
        "completed": 0,
        "confirmed": true,
        "confirmationbymail": true,
        "confirmationbyfax": false,
        "confirmationonsite": false,
        "confirmedby": null,
        "confirmationsent": null,
        "confirmeddate": null,
        "ordersource": 2,
        "deliverycanceled": false,
        "deliverycanceledcause": null,
        "orderto": 0,
        "priority": 0,
        "fixedappointment": false,
        "comment": "asdf",
        "externalremark": null,
        "inprogress": 0,
        "remarks": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
        "paymethod": 1,
        "ordercategory": null,
        "callercompany": "",
        "callercompany2": "",
        "callercompany3": "",
        "callergender": "",
        "callerfirstname": "",
        "callerlastname": "",
        "callerstreet": "",
        "callerzipcode": "",
        "callercity": "",
        "callerphone": "",
        "callermobile": "",
        "callerfax": "",
        "calleremail": "",
        "locationcompany": "Sanitär Wahl GmbH ",
        "locationcompany2": "",
        "locationcompany3": "",
        "locationgender": "",
        "locationfirstname": "",
        "locationlastname": "Wahl ",
        "locationstreet": "Unter dem Birkenkopf 16",
        "locationunit": "",
        "locationzipcode": "70193",
        "locationcity": "Stuttgart",
        "locationphone": "0711-656590",
        "locationmobile": "",
        "locationfax": "",
        "locationemail": "",
        "locationcontactgender": "Herr",
        "locationcontactfirstname": "",
        "locationcontactlastname": "Kohler ",
        "locationcontactphone": "0152-6622532",
        "invoicecompany": "Sanitär Wahl GmbH ",
        "invoicecompany2": "",
        "invoicecompany3": "",
        "invoicegender": "Firma",
        "invoicefirstname": "Harald",
        "invoicelastname": "Wahl ",
        "invoicestreet": "Unter dem Birkenkopf 23",
        "invoicezipcode": "70193",
        "invoicecity": "Stuttgart",
        "invoicephone": "0711-656590",
        "invoicemobile": "",
        "invoicefax": "",
        "invoiceemail": "nataliewahl@web.de",
        "housecontactname": null,
        "housecontactphone": null,
        "entrytext": null,
        "conditions": null,
        "departurelatitude": 0,
        "departurelongitude": 0,
        "departuretime": "2021-02-22T14:48:26+00:00",
        "arrivaltime": "2021-02-22T14:48:26+00:00",
        "starttime": "2021-02-22T14:48:26+00:00",
        "endtime": null,
        "doctotal": 216.6,
        "docdiscount": 0,
        "docvat": 41.154,
        "free1": null,
        "multiday": false,
        "free2": null,
        "free3": null,
        "free4": null,
        "free5": null,
        "free6": null,
        "provisioncardid": null,
        "provisioncardvalue": null,
        "wiederrufsbelehrung": 0,
        "exported": false,
        "invoiceid": 0,
        "lines": [
            {
                "@id": "/order_lines/496",
                "@type": "OrderLine",
                "id": 496,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T00:00:00+00:00",
                "dateto": "0001-01-01T00:00:00+00:00",
                "itemid": 27,
                "positiontext": "Vorlage verstopfte Spüle",
                "unitprice": 0,
                "quantity": 1,
                "uom": null,
                "discount": 0,
                "linesum": 0,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            },
            {
                "@id": "/order_lines/497",
                "@type": "OrderLine",
                "id": 497,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T00:00:00+00:00",
                "dateto": "0001-01-01T00:00:00+00:00",
                "itemid": 25,
                "positiontext": "[ - ] 1. Monteur",
                "unitprice": 4.6,
                "quantity": 12,
                "uom": "AW",
                "discount": 0,
                "linesum": 55.2,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            },
            {
                "@id": "/order_lines/498",
                "@type": "OrderLine",
                "id": 498,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T00:00:00+00:00",
                "dateto": "0001-01-01T00:00:00+00:00",
                "itemid": 22,
                "positiontext": "[ - ] 2. Monteur",
                "unitprice": 3.7,
                "quantity": 12,
                "uom": "AW",
                "discount": 0,
                "linesum": 44.4,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            },
            {
                "@id": "/order_lines/499",
                "@type": "OrderLine",
                "id": 499,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T00:00:00+00:00",
                "dateto": "0001-01-01T00:00:00+00:00",
                "itemid": 21,
                "positiontext": "[ - ] An-/Abfahrt",
                "unitprice": 39.5,
                "quantity": 1,
                "uom": "Pauschale",
                "discount": 0,
                "linesum": 39.5,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            },
            {
                "@id": "/order_lines/500",
                "@type": "OrderLine",
                "id": 500,
                "empid": null,
                "empname": null,
                "additionalpercent": 0,
                "additionaltext": null,
                "datefrom": "0001-01-01T00:00:00+00:00",
                "dateto": "0001-01-01T00:00:00+00:00",
                "itemid": 7,
                "positiontext": "[ - ] Geräteeinsatzwagen",
                "unitprice": 77.5,
                "quantity": 1,
                "uom": "Std",
                "discount": 0,
                "linesum": 77.5,
                "baseline": null,
                "linestate": "O",
                "basedoc": null
            }
        ],
        "vehicles": [
            {
                "@id": "/order_vehicles/174",
                "@type": "OrderVehicle",
                "id": 174,
                "date": "2021-03-25T00:00:00+00:00",
                "datefrom": "2021-03-25T10:40:00+00:00",
                "dateto": "2021-03-25T11:40:00+00:00",
                "deleted": false,
                "lastupdate": "2021-01-01T00:00:00+00:00",
                "vehicle": {
                    "@id": "/vehicles/167",
                    "@type": "Vehicle",
                    "id": 167,
                    "name": "ES-SY-003",
                    "licenseplate": "ES-SY-003",
                    "insurancecontract": "",
                    "make": "Fiat",
                    "model": "",
                    "color": "",
                    "rating": 0,
                    "vin": "",
                    "group": {
                        "@id": "/groups/2",
                        "@type": "Group",
                        "id": 2,
                        "groupname": "Rohrreiniger",
                        "useinplanner": true
                    },
                    "requirements": "1",
                    "maintenancetype": null,
                    "gpstrackingid": 5078,
                    "insurancecompany": "",
                    "insurancenumber": "",
                    "insurancevk": 0,
                    "insurancetk": 0,
                    "insurancehp": 0,
                    "insurancevksb": 0,
                    "insurancetksb": 0,
                    "insurancehpsb": 0,
                    "insurancevksf": 0,
                    "insurancetksf": 0,
                    "insurancehpsf": 0,
                    "schutzbrief": 0,
                    "nexthu": null,
                    "nextau": null,
                    "nextsp": null,
                    "nextuvv": null,
                    "leasingcompany": "UVW-Leasing",
                    "leasingnumber": "",
                    "leasingstart": null,
                    "leasingend": null,
                    "listprice": 71400,
                    "leasingcost": 1260,
                    "freekilometer": 0,
                    "costkilometer": 0,
                    "refundkilometer": 0,
                    "leasingcontract": "",
                    "remarks": "",
                    "imagepath": "",
                    "employee": ""
                },
                "employee": {
                    "@id": "/employees/112",
                    "@type": "Employee",
                    "id": 112,
                    "number": null,
                    "salutation": "Herr",
                    "firstname": "Meta",
                    "lastname": "Musterer",
                    "zipcode": null,
                    "city": null,
                    "street": null,
                    "poststreet": null
                },
                "user": "/users/2",
                "employees": [
                    {
                        "@id": "/order_vehicle_employees/36",
                        "@type": "OrderVehicleEmployee",
                        "id": 36,
                        "employee": {
                            "@id": "/employees/112",
                            "@type": "Employee",
                            "id": 112,
                            "number": null,
                            "salutation": "Herr",
                            "firstname": "Meta",
                            "lastname": "Musterer",
                            "zipcode": null,
                            "city": null,
                            "street": null,
                            "poststreet": null
                        }
                    },
                    {
                        "@id": "/order_vehicle_employees/37",
                        "@type": "OrderVehicleEmployee",
                        "id": 37,
                        "employee": {
                            "@id": "/employees/122",
                            "@type": "Employee",
                            "id": 122,
                            "number": null,
                            "salutation": "Herr",
                            "firstname": "Ahmed",
                            "lastname": "Pamukchiev",
                            "zipcode": null,
                            "city": null,
                            "street": null,
                            "poststreet": null
                        }
                    }
                ]
            }
        ],
        "notes": [],
        "rapports": [],
        "invoices": [],
        "attachments": [],
        videos: [
            {
                name: "v1-210120-11.30",
                id: "1",
                thumb: require("@/assets/temp/1.jpeg")
            },
            {
                name: "v2-210120-11.37",
                id: "2",
                thumb: require("@/assets/temp/2.jpeg")
            },
            {
                name: "v2-210120-13.37",
                id: "3",
                thumb: require("@/assets/temp/3.jpeg")
            }
        ],
        images: [
            {
                name: "i1-210120-11.30",
                id: "3",
                thumb: require("@/assets/temp/3.jpeg")
            },
            {
                name: "i2-210120-11.37",
                id: "4",
                thumb: require("@/assets/temp/4.jpeg")
            },
            {
                name: "i3-210120-11.37",
                id: "5",
                thumb: require("@/assets/temp/5.jpeg")
            }
        ]
    }

]

const state = reactive({
    orders: sampleOrders2,
    sampleMapData: {
        lat: 48.770542176972874,
        lng: 9.181168363242056
    },
    sampleOperator: { phone: "+4915730652235" }
});

const nextUncompletedOrder = computed(() => {
    return state.orders.find(a => a.completed == 0)
})

const completedOrders = computed(() => {
    return state.orders.filter(a => a.completed == 1)
})

const uncompletedOrders = computed(() => {
    return state.orders.filter(a => a.completed == 0)
})

async function updateOrder(orderToUpdate) {
    let found = state.orders.find(a => a.id == orderToUpdate.id)
    if (!!found) {
        let index = state.orders.indexOf(found)
        state.orders.splice(index, 1, orderToUpdate)
    }
}

async function markOrderAsDone(orderId) {
    state.orders = state.orders.map(a => {
        if (a.id == orderId) a.completed = 1
        return a
    })
    // console.log('zaznaczylem', state.orders)
}

function removeVideo(orderId, videoId) {
    state.orders = state.orders.map(a => {
        if (a.id == orderId) {
            a.videos = a.videos.filter(b => b.id != videoId)
            return a
        }
        return a
    })
}
function removeImage(orderId, imageId) {
    state.orders = state.orders.map(a => {
        if (a.id == orderId) {
            a.images = a.images.filter(b => b.id != imageId)
            return a
        }
        return a
    })
}



export function useState() {
    return { orders: readonly(state.orders), sampleMapData: readonly(state.sampleMapData), sampleOperator: readonly(state.sampleOperator), nextUncompletedOrder, completedOrders, uncompletedOrders, updateOrder, markOrderAsDone, removeVideo, removeImage }
};