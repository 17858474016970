


<template lang="pug">
div(
    id="drawer-content"
    style="background-color: var(--ion-color-accent); width: 100%; position: relative"
)
    div(
        style="height: 100vh"
        class="d-flex flex-column justify-space-between"
    )
        div
            //- close button
            div(
                class="d-flex justify-end"
                style="margin-bottom: 50px; margin-top: 20px"
            )
                IonButton(
                    @click="closeDrawerMenu"
                    color="accent"
                    style="font-size: 20px"
                )
                    IonText(
                        color="secondary"
                    )
                        h3 Schließen
                    IonIcon(
                        :icon="closeCircle"
                        color="secondary"
                        class="ml-2"
                    )

            //- menu items
            DrawerExpansionPanel(
                v-for="(item, index) in drawerItems"
                key="index"
                :item="item"
                v-model="drawerItems[index].expanded"
                style="margin-bottom: 1px; z-index: 1"
                class="relative"
                @closeDrawerMenu="closeDrawerMenu"
            )

        //- logout button
        div(
            class="d-flex justify-end"
            style="margin-bottom: 30px"
        )
            IonButton(
                color="accent"
                style="font-size: 20px"
                @click="logUserOutProceed"
            )
                IonText(
                    color="secondary"
                )
                    h3 App beenden
                IonIcon(
                    :icon="logOutOutline"
                    color="secondary"
                    class="ml-2"
                )

    //- background image
    div(
        class="bg-image-wrapper d-flex align-center justify-center"
        style="overflow: hidden"
    )
        img(
            src="@/assets/images/bg-logo-light.png"
        )
</template>


<script>
import DrawerExpansionPanel from "@/components/molecules/drawer/DrawerExpansionPanel"
import { closeCircle, logOutOutline } from "ionicons/icons"
import { ref } from "vue"
import { useAuth } from "@/composables/auth"
import { useRouter } from "vue-router"
import { useUtilities } from "@/composables/utilities"

export default {
    components: {
        DrawerExpansionPanel
    },
    setup(_, { emit }) {
        closeCircle
        logOutOutline

        const drawerItems = ref([
            {
                title: "Bereiche",
                subitems: [
                    {
                        title: "Administration",
                        link: null
                    },
                    {
                        title: "Buchhaltung",
                        link: null
                    },
                    {
                        title: "Technik",
                        link: "/order-processing"
                    }
                ],
                expanded: false
            },
            {
                title: "Kommunikation",
                subitems: [
                    {
                        title: "Einsatzzentrale",
                        link: "/communication/messenger"
                    }
                ],
                expanded: false
            },
            {
                title: "Zeiterfaßung",
                subitems: false,
                expanded: false,
                link: "/time-recording"
            }
        ])

        // logout
        const emitter = useUtilities().getEmitter()

        function closeDrawerMenu() {
            drawerItems.value.forEach((a) => (a.expanded = false))
            emitter.emit("closeDrawerMenu")
        }

        const { logUserOut } = useAuth()
        const router = useRouter()
        function logUserOutProceed() {
            emitter.emit("setLoadingModal", true)
            logUserOut()
            closeDrawerMenu()
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal")
            }, 1000)
            router.push({ name: "sign-in" })
        }

        return {
            drawerItems,
            closeDrawerMenu,
            closeCircle,
            logOutOutline,
            logUserOutProceed
        }
    }
}
</script>

<style lang="sass" scoped>
.bg-image-wrapper
    width: 100%
    height: 100%
    max-height: 100vh
    position: absolute
    top: 0
    left: 0
    pointer-events: none
    img
        width: 100%
        height: auto
        transform: scale(1.7)
        opacity: 0.05
</style>
