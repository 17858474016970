<template lang="pug">
IonCard(
    color="accent"
    class="pb-5 pt-5 pl-5 pr-5"
    style="width: 100%; border: 3px solid var(--ion-color-primary)"
)
    h3(class="text-center mb-4") {{ questionText }}
    IonRow
        IonCol(
            size="6"
        )
            IonButton(
                @click="$emit('confirm')"
                color="secondary"
                size="large"
                expand="block"
            )
                IonText(
                    color="primary"
                )
                    h3 {{ confirmText }}
        IonCol(
            size="6"
        )
            IonButton(
                @click="$emit('decline')"
                color="secondary"
                size="large"
                expand="block"
            )
                IonText(
                    color="primary"
                )
                    h3 {{ declineText }}
</template>

<script>
import { watch } from "vue"
import { useRoute } from "vue-router"
export default {
    props: {
        questionText: {
            type: String,
            default: "Bist du sicher?"
        },
        confirmText: {
            type: String,
            default: "Ja"
        },
        declineText: {
            type: String,
            default: "Nein"
        }
    },

    setup(_, { emit }) {
        const route = useRoute()

        // ensures that modal is closed due to route change
        watch(
            () => route.name,
            () => {
                emit("decline")
            }
        )
        return {}
    }
}
</script>