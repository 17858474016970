<template lang="pug">
IonPage
    div(
        ref="test"
    )
    //- style="min-height: 100vh"
    //- app bar
    IonHeader(class="ion-no-border")
        IonToolbar(
            color="white"
        )
            //- logo
            IonButtons(
                slot="start"
            )
                IonButton(
                    @click="$router.push('/')"
                )
                    img(
                        src="@/assets/images/logo.png"
                        height="30"
                    )

            //- hamburger
            IonButtons(
                slot="end"
            )
                IonButton(
                    @click="openDrawerMenu"
                )
                    IonText(
                        color="primary"
                    )
                        h3 Menü
                    img(
                        src="@/assets/icon/menu.png"
                        height="28"
                        class="ml-2"
                    )

    IonContent(
        class="relative"
        :style="[light ? { '--background': 'var(--ion-color-quinary)' } : { '--background': 'var(--ion-color-primary)' }]"
    )
        //- refresher
        IonRefresher(
            @ionRefresh="(e) => refreshContent(e)"
            slot="fixed"
        )
            IonRefresherContent

        //- rounding
        CurvedPageTop

        //- content
        Suspense
            template(
                #default
            )
                slot
            template(
                #fallback
            )
                div Loading...

        //- background image
        div(
            class="bg-image-wrapper d-flex align-center justify-center"
            style="overflow: hidden"
        )
            img(
                :src="backgroundImagePath"
            )
</template>

<script>
import { useUtilities } from "@/composables/utilities"
import { watch, ref } from "vue"
import { useRoute } from "vue-router"

import CurvedPageTop from "@/components/molecules/CurvedPageTop"

export default {
    components: { CurvedPageTop },

    props: {
        light: Boolean
    },

    computed: {
        backgroundColor() {
            return this.light
                ? "var(--ion-color-quinary)"
                : "var(--ion-color-primary)"
        },
        backgroundImagePath() {
            return this.light
                ? require("@/assets/images/bg-logo-light.png")
                : require("@/assets/images/bg-logo.png")
        }
    },

    setup() {
        // bus
        const emitter = useUtilities().getEmitter()

        function openDrawerMenu() {
            emitter.emit("openDrawerMenu")
        }

        const refreshContent = async (e) => {
            try {
                await refreshJobs()
                e.target.complete()
            } catch (error) {
                e.target.complete()
            }
        }

        // scroll
        let test = ref(null)
        const route = useRoute()
        watch(
            route,
            () => {
                // window.scroll({
                //     top: 0,
                //     // left: 0,
                //     behavior: "smooth"
                // })
                // console.log("scroll", test.value)
                // if (test?.value) test.value.scrollToTop()
            },
            { immediate: true }
        )

        return { openDrawerMenu, refreshContent, test }
    }
}
</script>
<style lang="sass" scoped >
ion-content
    // --background: v-bind(backgroundColor)
.bg-image-wrapper
    width: 100%
    height: 100%
    max-height: 100vh
    position: absolute
    top: 0
    left: 0
    z-index: -1
    pointer-events: none
    img
        width: 100%
        height: auto
        transform: scale(1.7)
        // opacity: 0.05
</style>