<template lang="pug">
div(
    style="width: 100%; height: 100%; background-color: rgba(10, 42, 77, 0.81)"
    class="d-flex flex-column align-center justify-space-between"
)
    Container(
        style="overflow-y: scroll; width: 100%; flex-grow: 1"
    )
        slot

    //- bottom panel
    div(
        style="background-color: var(--ion-color-accent); width: 100%"
        class="pb-5 pt-5"
        v-if="!secondVariant"
    )
        Container(class="d-flex align-center justify-space-between")
            IonText(
                class="text-center pr-3"
                color="white"
            ) {{ questionText }}

            div(class="d-flex align-center")
                CustomCheckableIcon(
                    @click="$emit('confirm')"
                    checked
                    class="mr-2"
                )
                CustomCheckableIcon(
                    @click="$emit('decline')"
                    checked
                    fileName="decline.png"
                    v-if="!confirmButtonOnly"
                )
    div(
        v-if="!!secondVariant"
        style="width: 100%"
        class="pb-5 pt-5"
    )
        Container
            IonRow(class="receding btn-container")
                IonCol(
                    size="12"
                    class="pb-0"
                    v-if="additionalButton"
                )
                    IonButton(
                        @click="$emit('additionalButtonClick')"
                        color="secondary"
                        size="large"
                        expand="block"
                    )
                        IonText(
                            color="primary"
                        )
                            h3 {{ additionalButtonText }}

                IonCol(
                    size="12"
                    class="pb-0"
                )
                    IonButton(
                        @click="$emit('confirm')"
                        color="secondary"
                        size="large"
                        expand="block"
                    )
                        IonText(
                            color="primary"
                        )
                            h3 {{ confirmText }}

                IonCol(
                    size="12"
                    class="pb-0"
                    v-if="!confirmButtonOnly"
                )
                    IonButton(
                        @click="$emit('decline')"
                        color="accent"
                        size="large"
                        expand="block"
                    )
                        IonText(
                            color="white"
                        )
                            h3 {{ declineText }}
</template>

<script>
import { watch } from "vue"
import { useRoute } from "vue-router"
import CustomCheckableIcon from "@/components/molecules/CustomCheckableIcon"
export default {
    components: {
        CustomCheckableIcon
    },

    props: {
        questionText: {
            type: String,
            default: "Bist du sicher?"
        },
        secondVariant: {
            type: Boolean
        },
        confirmButtonOnly: {
            type: Boolean
        },
        confirmText: {
            type: String,
            default: "Bestätigen"
        },
        declineText: {
            type: String,
            default: "Abbruch"
        },
        additionalButton: {
            type: Boolean,
            default: false
        },
        additionalButtonText: {
            type: String,
            default: ""
        }
    },

    setup(_, { emit }) {
        const route = useRoute()

        // ensures that modal is closed due to route change
        watch(
            () => route.name,
            () => {
                emit("decline")
            }
        )
        return {}
    }
}
</script>