<template lang="pug">
div(
    style="color: var(--ion-color-secondary)"
)
    Container
        div(
            class="d-flex justify-space-between mb-4"
            style="width: 100%"
        )
            div Start: 08:00
            div Ende: 17:30
    Container
        div(
            style="width: 100%; border-left: 1px dashed var(--ion-color-secondary); border-right: 1px dashed var(--ion-color-secondary)"
            class="pt-6"
        )
            Timeline(
                class="mb-2"
                :variant="1"
            )
                span P
            Timeline(
                class="mb-2"
                :variant="2"
            )
                span F
            Timeline(
                :variant="3"
            )
                span A
</template>

<script>
import Timeline from "@/components/molecules/Timeline"
export default {
    components: {
        Timeline
    }
}
</script>