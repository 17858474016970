import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
// import './theme/variables.css';
import './assets/theme.css';
import './assets/global.sass';

// global components
import BaseLayout from '@/components/layouts/BaseLayout'
import Container from '@/components/global/Container'
import Spacer from '@/components/global/Spacer'

// ionic components
import * as IonComponents from '@ionic/vue';

// vue3-signature
import Vue3Signature from "vue3-signature"

// modal
import VueFinalModal from 'vue-final-modal'

// bus
import mitt from 'mitt';
const emitter = mitt();

const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .component('BaseLayout', BaseLayout)
    .component('Container', Container)
    .component('Spacer', Spacer)
    .use(Vue3Signature)
    .use(VueFinalModal())

app.config.globalProperties.emitter = emitter

// imports all ionic components as global
Object.keys(IonComponents).forEach(key => {
    if (/^Ion[A-Z]\w+$/.test(key)) {
        app.component(key, IonComponents[key]);
    }
});


router.isReady().then(() => {
    app.mount('#app');
});