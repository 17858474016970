<template lang="pug">
div(
    class="drawer-expansion-panel shadow"
    :class="{ expanded: expanded }"
)
    //- header
    IonButton(
        style="width: 100%; height: 60px; --padding-start: 0; --padding-end: 0; margin: 0"
        expand="full"
        color="accent"
        class="drawer-item-header"
        @click="handleItemClick"
    )
        div(
            style="width: 100%; height: 100%"
            class="item-content d-flex align-center justify-start"
            :class="isExactRoute(item) ? 'exact' : ''"
        )
            div(
                style="height: 100%; width: 50px; background-color: var(--ion-color-primary)"
                class="radius-right overflow-hidden d-flex align-center justify-center"
            )
                div(class="item-icon d-flex align-center justify-center")
                    IonIcon(
                        :icon="chevronForwardCircle"
                        color="secondary"
                        style="font-size: 22px"
                    )

            h3(class="ml-5") {{ item.title }}

    //- content
    div(
        class="content-wrapper"
        ref="wrapper"
    )
        div(
            ref="content"
        )
            IonButton(
                v-for="(subitem, index) in item.subitems"
                :key="index"
                class="d-flex align-center"
                color="tertiary"
                expand="full"
                @click="handleSubitemClick(subitem)"
                style="margin: 0; --padding-start: 0; --padding-end: 0; height: auto"
                :disabled="!isActive(subitem)"
            )
                //- style="background-color: var(--ion-color-tertiary)"
                div(
                    style="margin-left: 48px; border-left: 1px dashed var(--ion-color-accent); width: 100%"
                    class="subitem-content pt-4 pb-4 pl-5"
                    :class="isExactRoute(subitem) ? 'exact' : !isActive(subitem) ? 'inactive' : ''"
                )
                    h3(
                        style="text-align: left"
                    ) {{ subitem.title }}

                    div(
                        style="position: absolute; top: 0; left: 29px; height: 100%; width: 40px"
                        class="d-flex align-center justify-center"
                    )
                        CustomIcon(
                            :fileName="isExactRoute(subitem) ? 'chevron-right-2.png' : 'chevron-right.png'"
                            size="28"
                        )
</template>

<script>
import { chevronForwardCircle } from "ionicons/icons"
import { watch, ref } from "vue"
import { useRouter, useRoute } from "vue-router"
import CustomIcon from "@/components/molecules/CustomIcon"
export default {
    components: { CustomIcon },
    watch: {
        expanded(val) {
            this.$refs.wrapper.style.height =
                this.$refs.content.offsetHeight + "px"
            if (val) {
                setTimeout(() => {
                    this.$refs.wrapper.style.height = "auto"
                }, 250)
            } else {
                setTimeout(() => {
                    this.$refs.wrapper.style.height = 0
                }, 10)
            }
        }
    },

    props: {
        modelValue: Boolean,
        item: Object
    },

    setup(props, { emit }) {
        chevronForwardCircle

        const expanded = ref(false)

        watch(expanded, (val) => {
            emit("update:modelValue", val)
        })

        watch(
            () => props.modelValue,
            (val) => {
                expanded.value = val
            },
            { immediate: true }
        )

        function isActive(itemOrSubitem) {
            return itemOrSubitem.link
        }

        const route = useRoute()
        function isExactRoute(itemOrSubitem) {
            return route.path.includes(itemOrSubitem.link)
        }

        const router = useRouter()

        function handleItemClick() {
            !props.item.subitems && !!props.item.link
                ? (router.push(props.item.link), emit("closeDrawerMenu"))
                : (expanded.value = !expanded.value)
        }

        function handleSubitemClick(subitem) {
            !!subitem.link
                ? (router.push(subitem.link), emit("closeDrawerMenu"))
                : ""
        }

        return {
            expanded,
            chevronForwardCircle,
            handleItemClick,
            handleSubitemClick,
            isActive,
            isExactRoute
        }
    }
}
</script>

<style lang="sass" scoped>
.drawer-expansion-panel
    .content-wrapper
        overflow: hidden
        height: 0px
        transition: all .2s ease-in-out
        >div
            // padding: 0px 25px 30px

    .item-icon
        -webkit-transition: -webkit-transform .2s ease-in-out
        -ms-transition: -ms-transform .2s ease-in-out
        transition: transform .2s ease-in-out
    &.expanded
        .item-icon
            transform: rotate(90deg)
            -ms-transform: rotate(90deg)
            -webkit-transform: rotate(90deg)

    .subitem-content, .item-content
        h3
            color: white
        &.exact
            h3
                color: var(--ion-color-secondary)
        &.inactive
            h3
                color: var(--ion-color-primary)
                opacity: 0.6



</style>