import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import { useAuth } from '@/composables/auth'


const routes = [
    {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import('../pages/SignInPage.vue'),
        meta: {
            auth: false
        }
    },
    {
        path: '/',
        name: 'home',
        component: () => import('../pages/HomePage.vue'),
        redirect: '/order-processing',
        meta: {
            auth: true
        }
    },
    {
        path: '/order-processing',
        name: 'order-processing',
        component: () => import('../pages/order-processing/OrderProcessingPage.vue'),
        meta: {
            auth: true
        }
    },
    {
        path: '/order-processing/:id/step-1',
        name: 'order-1',
        meta: {
            isOrderStep: true,
            auth: true
        },
        component: () => import('../pages/order-processing/OrderStep1Page.vue')
    },
    {
        path: '/order-processing/:id/step-2',
        name: 'order-2',
        meta: {
            isOrderStep: true,
            auth: true
        },
        component: () => import('../pages/order-processing/OrderStep2Page.vue')
    },
    // {
    //     path: '/order-processing/:id/step-3',
    //     name: 'order-3',
    //     meta: {
    //         isOrderStep: true,
    //         auth: true
    //     },
    //     component: () => import('../pages/order-processing/OrderStep3Page.vue')
    // },
    {
        path: '/order-processing/:id/step-4',
        name: 'order-4',
        meta: {
            isOrderStep: true,
            auth: true
        },
        component: () => import('../pages/order-processing/OrderStep4Page.vue')
    },
    {
        path: '/order-processing/:id/step-5',
        name: 'order-5',
        meta: {
            isOrderStep: true,
            auth: true
        },
        component: () => import('../pages/order-processing/OrderStep5Page.vue')
    },
    {
        path: '/order-processing/:id/order-inventory',
        name: 'order-inventory',
        meta: {
            isOrderStep: true,
            auth: true
        },
        component: () => import('../pages/order-processing/OrderInventoryPage.vue')
    },
    {
        path: '/order-processing/:id/order-inventory-report',
        name: 'order-inventory-report',
        meta: {
            isOrderStep: true,
            auth: true
        },
        component: () => import('../pages/order-processing/OrderInventoryReportPage.vue')
    },
    {
        path: '/order-processing/:id/step-6',
        name: 'order-6',
        meta: {
            isOrderStep: true,
            auth: true
        },
        component: () => import('../pages/order-processing/OrderStep6Page.vue')
    },
    {
        path: '/order-processing/:id/step-7',
        name: 'order-7',
        meta: {
            isOrderStep: true,
            auth: true
        },
        component: () => import('../pages/order-processing/OrderStep7Page.vue')
    },
    {
        path: '/order-processing/:id/report',
        name: 'order-report',
        meta: {
            isOrderStep: true,
            auth: true
        },
        component: () => import('../pages/order-processing/OrderReportPage.vue')
    },
    {
        path: '/order-processing/:id/payment',
        name: 'order-payment',
        meta: {
            isOrderStep: true,
            auth: true
        },
        component: () => import('../pages/order-processing/OrderPaymentPage.vue')
    },
    {
        path: '/time-recording',
        name: 'time-recording',
        meta: {
            auth: true
        },
        component: () => import('../pages/TimeRecordingPage.vue')
    },
    {
        path: '/communication/messenger',
        name: 'messenger',
        meta: {
            auth: true
        },
        component: () => import('../pages/communication/MessengerMainPage.vue')
    },
    {
        path: '/communication/messenger/:id',
        name: 'messenger-id',
        meta: {
            auth: true
        },
        component: () => import('../pages/communication/MessengerPrivPage.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const { isLoggedIn } = useAuth()
const { logUserInFromStorage } = useAuth()

router.beforeEach((to, from, next) => {
    if (!to.meta.auth) {
        next()
    }
    else {
        if (isLoggedIn.value) {
            next()
        }
        else {
            logUserInFromStorage()

            if (isLoggedIn.value) {
                next()
            }
            else {
                next({ name: 'sign-in' })
            }
        }
    }
})

export default router
