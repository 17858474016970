import { computed, reactive, readonly } from "vue";

const state = reactive({
    step2NextBtn: null,
    orderInventoryReportNextBtn: null,
    doneInventories: [],
    stopwatchIsCountingBtn: null,
    orderReportNextBtn: null,
});

function setStep2NextBtn(val) {
    state.step2NextBtn = val
}

function setOrderInventoryReportNextBtn(val) {
    state.orderInventoryReportNextBtn = val
}

function setStopwatchIsCountingBtn(val) {
    state.stopwatchIsCountingBtn = val
}

function setOrderReportNextBtn(val) {
    state.orderReportNextBtn = val
}

function addInventoryToDone(orderId) {
    if (!state.doneInventories.includes(orderId)) state.doneInventories.push(orderId)
}

export function useBtnController() {
    return { step2NextBtn: computed(() => state.step2NextBtn), setStep2NextBtn, doneInventories: readonly(state.doneInventories), addInventoryToDone, orderInventoryReportNextBtn: computed(() => state.orderInventoryReportNextBtn), setOrderInventoryReportNextBtn, setStopwatchIsCountingBtn, stopwatchIsCountingBtn: computed(() => state.stopwatchIsCountingBtn), orderReportNextBtn: computed(() => state.orderReportNextBtn), setOrderReportNextBtn }
};