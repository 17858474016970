import { reactive, readonly, ref, watch, computed, getCurrentInstance, onMounted } from "vue";
import { useState } from "@/composables/state"


// current order
const { orders, updateOrder } = useState()
let currentOrderId = ref(null);
function setCurrentOrderId(val) {
    currentOrderId.value = val
}
const currentOrder = computed(() => orders.find(a => a.id == currentOrderId.value))

// current chat person
const currentChatPerson = ref(null)

// bus
const getEmitter = function () {
    let em = getCurrentInstance().appContext.config.globalProperties.emitter
    return em
}

// text trimming function
function maxChar(val, number) {
    if (!number) return val;
    else if (!val) return '';
    else if (val.length < number) return val;

    else {
        let cutText = val.slice(0, number);
        do {
            cutText = cutText.slice(0, -1);
        } while (cutText[cutText.length - 1] != " ");
        return cutText + "...";
    }
}

export function useUtilities() {
    return { currentOrder, setCurrentOrderId, getEmitter, maxChar, currentChatPerson }
};