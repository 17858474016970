<template lang="pug">
div(
    :style="computedStyle"
    style="border-radius: 50%"
    class="d-flex align-center justify-center"
)
    img(
        style="height: 55%; width: 55%"
        :src="imagePath"
    )
</template>

<script>
import { computed } from "vue"
export default {
    props: {
        size: {
            type: String,
            default: "30"
        },
        fileName: {
            type: String
        },
        backgroundColor: {
            type: String
        }
    },

    setup(props) {
        const imagePath = computed(() => {
            return require(`@/assets/icon/${props.fileName}`)
        })
        const computedStyle = computed(() => {
            let style = `
                width: ${props.size}px; height: ${props.size}px;

                background-color: ${props.backgroundColor}
            `
            return style
        })
        return { computedStyle, imagePath }
    }
}
</script>