<template lang="pug">
ion-app(
    ref="app"
)
    ion-router-outlet
    ModalController

    ButtonsController

    //- drawer
    IonMenu(
        side="end"
        menuId="drawer"
        contentId="drawer-content"
        style="--background: var(--ion-color-accent); --width: 85%; height: 100vh"
    )
        DrawerContent
</template>


<script>
import { defineComponent, watch, computed, getCurrentInstance, ref } from "vue"
import { useRoute } from "vue-router"
import { menuController } from "@ionic/vue"
import { useUtilities } from "@/composables/utilities"

import ModalController from "@/components/molecules/modals/ModalController"
import DrawerContent from "@/components/molecules/drawer/DrawerContent"
import ButtonsController from "@/components/molecules/ButtonsController"

export default defineComponent({
    name: "App",

    components: {
        ModalController,
        DrawerContent,
        ButtonsController
    },

    setup() {
        const { setCurrentOrderId } = useUtilities()

        const route = useRoute()

        // bus
        const emitter = useUtilities().getEmitter()

        const app = ref(null)
        // emitter.on("scroll-top", () => {
        //     setTimeout(() => {
        //         // window.scrollTo(0, 0)
        //         // console.log("scroll-top")
        //         // console.log(app.value)
        //         // app.value.scrollIntoView()
        //         // console.log(window)
        //         // window.scroll({
        //         //     top: 0,
        //         //     left: 0,
        //         //     behavior: "smooth"
        //         // })
        //     }, 1000)
        // })

        watch(
            route,
            () => {
                setCurrentOrderId(route.params.id)
            },
            { immediate: true }
        )

        // drawer
        emitter.on("openDrawerMenu", () => {
            menuController.enable(true, "drawer")
            menuController.open("drawer")
        })
        emitter.on("closeDrawerMenu", () => {
            menuController.close("drawer")
        })

        return { app, setCurrentOrderId }
    }
})
</script>
