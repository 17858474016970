<template lang="pug">
CustomModal(
    v-model="successModalOpened"
)
    SuccessModalContent(
        :text="successModalText"
    )
CustomModal(
    v-model="loadingModalOpened"
)
    LoadingModalContent
</template>

<script>
import { ref, getCurrentInstance } from "vue"
import CustomModal from "@/components/molecules/modals/CustomModal"
import SuccessModalContent from "@/components/molecules/modals/SuccessModalContent"
import LoadingModalContent from "@/components/molecules/modals/LoadingModalContent"
import { useUtilities } from "@/composables/utilities"
export default {
    components: {
        CustomModal,
        SuccessModalContent,
        LoadingModalContent
    },
    setup() {
        const successModalOpened = ref()
        const successModalText = ref()
        const loadingModalOpened = ref()

        // bus
        const emitter = useUtilities().getEmitter()

        // events handling
        emitter.on("showSuccessModal", (text) => {
            successModalText.value = text
            successModalOpened.value = true
            setTimeout(() => (successModalOpened.value = false), 1000)
        })

        emitter.on("setLoadingModal", (val) => {
            loadingModalOpened.value = val
        })

        return { successModalOpened, loadingModalOpened, successModalText }
    }
}
</script>