<template lang="pug">
div(
    :class="small ? 'pt-3' : 'pt-6'"
)
</template>

<script>
export default {
    props: {
        small: Boolean
    }
}
</script>