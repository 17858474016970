<template lang="pug">
div(
    style="width: 100%; background-color: var(--ion-color-accent); height: 25px; position: relative"
)
    div(
        style="position: absolute; left: -15px; top: 0; height: 100%"
        class="d-flex align-center"
    )
        slot

    div(
        v-if="variant == 1"
        class="d-flex align-center justify-space-between"
        style="height: 100%"
    )
        div(
            style="width: 20px"
        )
        div(
            class="timeline-block"
            style="width: 40px"
        )
        div
        div(
            class="timeline-block"
            style="width: 60px"
        )
        div
        div(
            class="timeline-block"
            style="width: 30px"
        )
        div(
            style="width: 100px"
        )
    div(
        v-if="variant == 2"
        style="height: 100%"
        class="d-flex align-center justify-space-between"
    )
        div
        div(
            style="width: 80px"
            class="timeline-block"
        )
        div(
            style="width: 150px"
        )
    div(
        v-if="variant == 3"
        style="height: 100%"
        class="d-flex align-center justify-space-between"
    )
        div(
            style="width: 60%"
            class="timeline-block"
        )
        div
</template>

<script>
export default {
    props: ["variant"]
}
</script>

<style lang="sass" scoped>
.timeline-block
    background-color: var(--ion-color-secondary)
    height: 100%
</style>